import React, { useEffect, useState } from 'react';
import { 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    Checkbox,
    IconButton,
    Tooltip,
    CircularProgress,
    Zoom,
    createTheme,
    ThemeProvider,
    LinearProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import { download, getRightColor, TransitionLeft } from '../../../helpers/utils';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useDocuments } from '../../../hooks/documents';
import AddDocument from './DocumentItem/AddDocument';
import { apiFetch } from '../../../service/apiFetch';
import * as locales from '@mui/material/locale';
import DeletedConfirmationPopup from '../../../components/DeletedConfirmationPopup';
import ChangeDatePopup from './DocumentItem/ChangeDatePopup';
import moment from 'moment'
import HandleRemindersPopup from './DocumentItem/HandleRemindersPopup';
import SubjectIcon from '@mui/icons-material/Subject';
import CommentPopup from './DocumentItem/CommentPopup';
import EditIcon from '@mui/icons-material/Edit';
import {isGestionPsy} from '../../../service/security/hasRoles';
import VisibilityIcon from '@mui/icons-material/Visibility';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Nom du document' },
    { id: 'expiredAt', numeric: false, disablePadding: true, label: 'Date d\'expiration/Nb relances' },
    { id: 'documentType', numeric: true, disablePadding: false, label: 'Type de document' },
    { id: 'degree', numeric: true, disablePadding: true, label: 'Date d\'obtention du diplôme' },
    { id: 'comment', numeric: true, disablePadding: false, label: 'Commentaire' },
    { id: 'download', numeric: true, disablePadding: false, label: 'Télécharger' },
    ...(isGestionPsy() ? [{ id: 'action', numeric: true, disablePadding: false, label: 'Action' }] : []),
];

const findHeadCells = () => {
    return headCells
}

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, darkState } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {isGestionPsy() && (
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                        color={darkState ? 'secondary' : 'primary'}
                    />
                </TableCell>
                )}
                {findHeadCells().map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{fontWeight: 'bold', color: darkState ? '#7fe1dd' : '#12047D' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span
                                    style={{
                                        border: 0,
                                        clip: 'rect(0 0 0 0)',
                                        height: 1,
                                        margin: -1,
                                        overflow: 'hidden',
                                        padding: 0,
                                        position: 'absolute',
                                        top: 20,
                                        width: 1,
                                    }}
                                >
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props) => {
    const { numSelected, handleDelete, handleOpenAddPopup } = props;

    return (
        <Toolbar>
            {numSelected > 0 ? (
                <Typography color="inherit" variant="subtitle1" component="div" sx={{flex: '1 1 100%'}}>
                    {numSelected} sélectionné(s)
                </Typography>
            ) : (
                <Typography variant="h6" id="tableTitle" component="div" sx={{flex: '1 1 100%'}}>
                    Documents
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip TransitionComponent={Zoom} title="Delete">
                    <IconButton color='inherit' aria-label="delete" onClick={handleDelete}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : isGestionPsy() && (
                <Tooltip TransitionComponent={Zoom} title="Ajouter un document">
                    <IconButton color='inherit' aria-label="filter list" onClick={handleOpenAddPopup}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};

export default function Documents({
    handleCloseBackdrop,
    handleToggle,
    setOpenSnackbar,
    setText,
    setTransition,
    setSeverity,
    darkState,
    zoom
}) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [openAddPopup, setOpenAddPopup] = useState(false);
    const [openChangePopup, setOpenChangePopup] = useState(false);
    const [name, setName] = useState('');
    const [selectError, setSelectError] = React.useState(false)
    const [showGraduationDate, setShowGraduationDate] = React.useState(false)
    const [comment, setOpenComment] = React.useState('')
    const [textComment, setTextComment] = React.useState('')
    const [documentType, setDocumentType] = useState('');
    const [files, setFiles] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [graduationDate, setGraduationDate] = useState(null);
    const [changeDate, setChangeDate] = useState(null);
    const [newDocuments, setNewDocuments] = useState([])
    const [loading, setLoading] = useState(false)
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [openRemindersPopup, setOpenRemindersPopup] = useState(false);
    const [locale] = useState('frFR');
    const [fileUrl, setFileUrl] = useState([]);
    const [loadingPage, setLoadingPage] = useState(false);
    const [modify, setModify] = useState(false)
    const { id } = useParams()
    let navigate = useNavigate()

    const {
        documents,
        totalItems,
        view,
        deleteDocument,
        fetchDocuments,
        createDocument,
    } = useDocuments()
    
    useEffect(() => {
        (async () => {
            const documentFetch = await fetchDocuments(`${id}`)
            if (documentFetch && documentFetch['hydra:description']) {
                setText('Error lors de la récupération des données')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigate('/specialists')
            }
        })()
    }, [id, fetchDocuments, navigate, setTransition, setText, setOpenSnackbar, setSeverity])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = documents.map((n) => n);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = async (event, newPage) => {
        setLoadingPage(true)
        if (newPage > page) {
          await fetchDocuments(`${id}`, () => ({ current: true }), false, view['hydra:next'])
        } else if (newPage < page) {
          await fetchDocuments(`${id}`, () => ({ current: true }), false, view['hydra:previous'])
        }
        setLoadingPage(false)
        setPage(newPage);
      };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handleConfirmationDelete = () => {
        setOpenChangePopup(false)
        setOpenDeletePopup(!openDeletePopup)
        setModify(false)
    }


    const handleDelete = async () => {
        handleToggle()
        setOpenDeletePopup(false)
        if (changeDate) {
            const deleteExpirationDate = await apiFetch(`/documents/${changeDate.uuid}`, {
                method: 'PUT',
                body: JSON.stringify({
                    "expiredAt": null
                })
            })
            if (deleteExpirationDate) {
                const documentFetch = await fetchDocuments(`${id}`, () => ({current: true}), true)
                if (documentFetch && documentFetch['hydra:description']) {
                    setText('Error lors de la récupération des données')
                    setSeverity('error')
                    setTransition(() => TransitionLeft)
                    setOpenSnackbar(true)
                    navigate('/specialists')
                }
                setText('La date de renouvellement a été supprimée')
                setTransition(() => TransitionLeft)
                setSeverity('success')
                setOpenSnackbar(true)
                setOpenChangePopup(false)
                handleCloseBackdrop()
            } else {
                setText(`Une erreur s'est produite lors de la suppression de la date`)
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
                handleCloseBackdrop()
            }
            setChangeDate(false)
        } else  {
            const requests = selected.map(async (item) => {
                const deletedDocument = await deleteDocument(item.uuid)
                if (deletedDocument && deletedDocument.ok) {
                    return true
                } else {
                    return item
                }
            })
    
            Promise.all(requests).then((request) => {
                const test = request.find((req) => req === true)
                if (test) {
                    setText('Le(s) document(s) a été supprimé(s)')
                    setTransition(() => TransitionLeft)
                    setSeverity('success')
                    setOpenSnackbar(true)
                    handleCloseBackdrop()
                } else {
                    let failItem = ''
                    request.map((item) => failItem += item.documentType.name + ' / ')
                    setText(`Erreur lors de la suppression des documents : ${failItem}`)
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setOpenSnackbar(true)
                    handleCloseBackdrop()
                }
            })
            setSelected([])
        }
    }

    const handleOpenAddPopup = () => {
        setOpenAddPopup(!openAddPopup)
    }

    const handleEditDocument = async () => {
        handleToggle()
        const data = {
            expiredAt: startDate,
            documentType: documentType,
            specialist: `specialists/${id}`,
            name: name,
            remark: textComment,
            graduationDate: graduationDate,
        }
        const editDocument = await apiFetch(`/documents/${openAddPopup.uuid}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        })

        if (editDocument && editDocument.uuid) {
            const documentFetch = await fetchDocuments(`${id}`, () => ({current: true}), true)
            if (documentFetch && documentFetch['hydra:description']) {
                setText('Error lors de la récupération des données')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigate('/specialists')
            }
            setText('Le document a été modifié')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setStartDate(null)
            setGraduationDate(null)
            setDocumentType('')
            setName('')
            setTextComment('')
            handleCloseBackdrop()
        } else {
            setText((editDocument && editDocument['hydra:description']) || 'Erreur lors de la modification')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
            handleCloseBackdrop()
        }
        setOpenAddPopup(false)
    }

    const handleAddDocument = () => {
        if (openAddPopup.uuid) {
            return handleEditDocument()
        }
        let createDocuments = []
        setSelectError(false)
        if (!documentType) {
            setLoading(false)
            return setSelectError('Veuillez sélectionner un type de document')
        }
        handleToggle()
        
        const requests = newDocuments.map(async (doc) => {
            let dataDocuments = new FormData()
            dataDocuments.append('file', doc[0])

            const data = await apiFetch('/document_objects', {
                method: 'POST',
                body: dataDocuments
            }, true)
            if (data && data['@id']) {
                createDocuments.push({
                    expiredAt: startDate,
                    document: data['@id'],
                    documentType: documentType,
                    specialist: `specialists/${id}`,
                    name: name,
                    graduationDate: graduationDate,
                })
                return true
            } else {
                setText((data && data['hydra:description']) || 'Erreur de téléchargement')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
                handleCloseBackdrop()
                return false
            }
        })

        let createdDocuments = null
        Promise.all(requests).then(async (request) => {
            const test = request.find((req) => req === false)
            if (typeof test !== 'undefined') return null
            createdDocuments = await createDocument(createDocuments[0])
            if (createdDocuments && createdDocuments.uuid) {
                setText('Le document a été ajouté')
                setTransition(() => TransitionLeft)
                setSeverity('success')
                setOpenSnackbar(true)
                setFiles([])
                setNewDocuments([])
                setFileUrl([])
                setStartDate(null)
                setGraduationDate(null)
                setDocumentType('')
                document.querySelector("#name").value = ''
                setName('')
                handleCloseBackdrop()
            } else {
                setText((createdDocuments && createdDocuments['hydra:description']) || 'Erreur lors de l\'ajout')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
                handleCloseBackdrop()
                return null
            }
        })
    }

    const handleDownload = async (row) => {
        const fetchBlob = await apiFetch(`/documents/${row.uuid}/download`)

        if (process.env.REACT_APP_S3_MODE === 'true') {
            const doc = await fetchBlob.json()
            if (doc.signedUrl) {
                const aws3Doc = await apiFetch(`${doc.signedUrl}`, {method: 'GET'}, true, true)
                if (aws3Doc.ok) {
                    const blob = await aws3Doc.blob()
                    download(blob, `${row.document.originalFileName}`)
                }
            }
            return
        }
        if (fetchBlob.ok) {
          const blob = await fetchBlob.blob()
          download(blob, `${row.document.originalFileName}`)
        }
    }

    const handleChangeDatePopup = (row) => {
        setChangeDate(row)
        setOpenChangePopup(true)
    }

    const handleRemindersPopup = (row) => {
        setOpenRemindersPopup(row)
    }

    const handleChangeDate = async () => {
        handleToggle()
        const changeDateFetch = await apiFetch(`/documents/${changeDate.uuid}`, {
            method: 'PUT',
            body: JSON.stringify({
                "expiredAt": startDate
            })
        })
        if (changeDateFetch) {
            const documentFetch = await fetchDocuments(`${id}`, () => ({current: true}), true)
            if (documentFetch && documentFetch['hydra:description']) {
                setText('Error lors de la récupération des données')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigate('/specialists')
            }
            setText('La date de renouvellement a été changée')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setOpenChangePopup(false)
            setModify(false)
            handleCloseBackdrop()
        } else {
            setText(`Une erreur s'est produite lors du changement de la date`)
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
            handleCloseBackdrop()
        }
        setChangeDate(false)
    }

    const handleCloseChangeDatePopup = () => {
        setOpenChangePopup(false)
        setModify(false)
    }

    const handleTooltipTitle = (array) => {
        let title = ""
        array.map((el) => {
            return title += `${moment(el.sentAt)} `
        })
        return title.length ? title : 'Pas de relance pour le moment'
    }

    const handleReminders = async () => {
        handleToggle()
        const changeRemindersStatus = await apiFetch(`/documents/${openRemindersPopup.uuid}`, {
            method: 'PUT',
            body: JSON.stringify({
                "activeReminder": openRemindersPopup.activeReminder ? false : true
            })
        })
        if (changeRemindersStatus && !changeRemindersStatus['hydra:description']) {
            const documentFetch = await fetchDocuments(`${id}`, () => ({current: true}), true)
            if (documentFetch && documentFetch['hydra:description']) {
                setText('Error lors de la récupération des données')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigate('/specialists')
            }
            setText(`Les relances sont ${openRemindersPopup.activeReminder ? 'désactiver' : 'activer'}`)
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setOpenChangePopup(false)
            setModify(false)
            handleCloseBackdrop()
        } else {
            setText(`Une erreur s'est produite lors du changement de relance`)
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
            handleCloseBackdrop()
        }
        setOpenRemindersPopup(false)
    }

    const handleShowComment = (row) => {
        setOpenComment(row)
    }

    const handleAddComment = (row) => {
        setOpenComment(row)
    }

    const addComment = async () => {
        handleToggle()
        const addDocumentComment = await apiFetch(`/documents/${comment.uuid}`, {
            method: 'PUT',
            body: JSON.stringify({
                "remark": textComment
            })
        })
        if (addDocumentComment && !addDocumentComment['hydra:description']) {
            const documentFetch = await fetchDocuments(`${id}`, () => ({current: true}), true)
            if (documentFetch && documentFetch['hydra:description']) {
                setText('Error lors de la récupération des données')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigate('/specialists')
            }
            setText(`Le commentaire a été ajouté`)
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setOpenChangePopup(false)
            setModify(false)
            handleCloseBackdrop()
        } else {
            setText(`Une erreur s'est produite lors de l'ajout du commentaire`)
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
            handleCloseBackdrop()
        }
        setOpenComment(false)
    }

    const openEditPopup = (row) => {
        setStartDate(moment(row.expiredAt))
        setName(row.name)
        setTextComment(row.remark)
        setDocumentType(row.documentType['@id'])
        setGraduationDate(row.graduationDate)
        if (row.documentType['name'] === 'Diplôme') {
            setShowGraduationDate(true)
        } else {
            setShowGraduationDate(false)
        }
        setOpenAddPopup(row)
    }

    const handleOpenShowPopup = async (row) => {
        const fetchBlob = await apiFetch(`/documents/${row.uuid}/download`)
        if (process.env.REACT_APP_S3_MODE === 'true') {
            const doc = await fetchBlob.json()
            if (doc.signedUrl) {
                const aws3Doc = await apiFetch(`${doc.signedUrl}`, {method: 'GET'}, true, true)
                if (aws3Doc.ok) {
                    const blob = await aws3Doc.blob()
                    const changeType = blob.slice(0, blob.size, "application/pdf")
                    const a = window.URL.createObjectURL(changeType)
                    window.open(a)
                }
            }
            return
        }
        if (fetchBlob.ok) {
            const blob = await fetchBlob.blob()
            const changeType = blob.slice(0, blob.size, "application/pdf")
            const a = window.URL.createObjectURL(changeType)
            window.open(a)
        }
    }

    return (
        <div>
            {documents ? 
            <Zoom in={zoom}>
                <Paper style={{boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)'}}>
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        handleDelete={handleConfirmationDelete}
                        handleOpenAddPopup={handleOpenAddPopup}
                    />
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={documents.length}
                                darkState={darkState}
                            />
                            <TableBody>
                                {stableSort(documents, getComparator(order, orderBy))
                                    /* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                            >
                                                {isGestionPsy() && (
                                                    <TableCell padding="checkbox" onClick={(event) => handleClick(event, row)}>
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                            color={darkState ? 'secondary' : 'primary'}
                                                        />
                                                    </TableCell>
                                                )}
                                                <TableCell component="th" id={labelId} scope="row" padding="normal">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} style={{cursor: isGestionPsy() ? 'pointer' : 'default'}} scope="row" padding="normal">
                                                    <div style={{display: 'flex'}}>
                                                        <div
                                                            onClick={isGestionPsy() ? () => handleChangeDatePopup(row) : null}
                                                            style={{
                                                                backgroundColor: row.status && getRightColor(row.status),
                                                                color: "white",
                                                                maxWidth: 160,
                                                                padding: 10,
                                                                borderTopLeftRadius: 4,
                                                                borderBottomLeftRadius: 4,
                                                                textAlign: 'center',
                                                                boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
                                                                borderRight: '1px solid #333'
                                                            }}
                                                        >
                                                        {row.expiredAt ? row.expiredAtText : 'Pas d\'expiration'}
                                                        </div>
                                                        <Tooltip TransitionComponent={Zoom} title={handleTooltipTitle(row.reminders)} style={{cursor: isGestionPsy() ? 'pointer' : 'default'}}>
                                                            <div
                                                                onClick={isGestionPsy() ? () => handleRemindersPopup(row) : null}
                                                                style={{
                                                                    backgroundColor: row.status && getRightColor(row.status),
                                                                    color: "white",
                                                                    maxWidth: 160,
                                                                    padding: 10,
                                                                    borderTopRightRadius: 4,
                                                                    borderBottomRightRadius: 4,
                                                                    textAlign: 'center',
                                                                    boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)'
                                                                }}
                                                            >
                                                                {row.reminders.length}
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="normal" style={{textAlign: 'end'}}>
                                                    {row.documentType.name}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="normal" style={{textAlign: 'end'}}>
                                                    {moment(row.graduationDate).isValid() ? moment(row.graduationDate).format('DD MMMM YYYY') : '-'}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="normal" style={{textAlign: 'end'}}>
                                                    {row.remark ? (
                                                            <IconButton onClick={() => handleShowComment(row)}>
                                                                <SubjectIcon />
                                                            </IconButton>
                                                        ) : (
                                                        isGestionPsy() && (
                                                            <Tooltip TransitionComponent={Zoom} title={'Ajouter un commentaire'}>
                                                                <IconButton color='inherit' onClick={() => handleAddComment(row)}>
                                                                    <AddIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )
                                                        )
                                                    }
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="normal" style={{textAlign: 'end'}}>
                                                    <IconButton color='inherit' onClick={() => handleDownload(row)}>
                                                        <GetAppIcon />
                                                    </IconButton>
                                                </TableCell>
                                                {isGestionPsy() && <TableCell
                                                    component="th"
                                                    scope="row"
                                                    padding="normal"
                                                    style={{textAlign: 'end'}}
                                                >
                                                    <IconButton color='inherit' onClick={() => handleOpenShowPopup(row)}>
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                    <IconButton color='inherit' onClick={() => openEditPopup(row)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </TableCell>}
                                            </TableRow>
                                        );
                                })}
                                {documents.length === 0 && (
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>Pas de documents...</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            component="div"
                            count={totalItems}
                            nextIconButtonProps={{ disabled: loadingPage || page >= (totalItems / 30) - 1}}
                            backIconButtonProps={{ disabled: loadingPage || page === 0 }} 
                            rowsPerPage={30}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                        </ThemeProvider>
                    <AddDocument
                        showGraduationDate={showGraduationDate}
                        setShowGraduationDate={setShowGraduationDate}
                        comment={textComment}
                        setComment={setTextComment}
                        name={name}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        fileUrl={fileUrl}
                        setFileUrl={setFileUrl}
                        setName={setName}
                        loading={loading}
                        darkState={darkState}
                        files={files}
                        setFiles={setFiles}
                        selectError={selectError}
                        newDocuments={newDocuments}
                        setDocumentType={setDocumentType}
                        documentType={documentType}
                        setNewDocuments={setNewDocuments}
                        graduationDate={graduationDate}
                        setGraduationDate={setGraduationDate}
                        open={openAddPopup}
                        handleClose={handleOpenAddPopup}
                        handleAddDocument={handleAddDocument}
                        />
                    <DeletedConfirmationPopup
                        handleAccept={handleDelete}
                        open={openDeletePopup}
                        handleClose={handleConfirmationDelete}
                        darkState={darkState}
                    />
                    <ChangeDatePopup
                        modify={modify}
                        setModify={setModify}
                        handleAccept={handleChangeDate}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        handleDelete={handleConfirmationDelete}
                        open={openChangePopup}
                        handleClose={handleCloseChangeDatePopup}
                        darkState={darkState}
                    />
                    <HandleRemindersPopup
                        handleAccept={handleReminders}
                        open={openRemindersPopup}
                        handleClose={() => setOpenRemindersPopup(false)}
                        darkState={darkState}
                    />
                    <CommentPopup
                        darkState={darkState}
                        text={textComment}
                        open={comment}
                        handleAccept={addComment}
                        setText={setTextComment}
                        handleClose={() => setOpenComment(false)}
                    />
                    {loadingPage && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
                </Paper>
            </Zoom>:
            <div style={{textAlign: 'center'}}>
                <CircularProgress color={darkState ? 'secondary' : 'primary'} />
            </div>}
        </div>
    );
}
